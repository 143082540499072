import { useIntl } from "react-intl";

import * as Styled from "./EnrolledSince.styles";
import { useEnrolledSince } from "./hooks/useEnrolledSince";

export default function EnrolledSince() {
  const { formatMessage } = useIntl();
  const { isLoading, pocHasClubB, formattedDate } = useEnrolledSince();

  if (isLoading) {
    return <Loading />;
  }
  if (!pocHasClubB) {
    return <></>;
  }

  return (
    <>
      <Styled.Title size="H4" data-testid="enrolled-since-title">
        {formatMessage({ id: "ClubB.TITLE" })}
      </Styled.Title>
      <Styled.Message>
        {formatMessage(
          {
            id: "ClubB.ENROLLED_SINCE",
          },
          { date: formattedDate },
        )}
      </Styled.Message>
    </>
  );
}

function Loading() {
  return (
    <>
      <Styled.LoadingTitle data-testid="enrolled-since-loading-title" />
      <Styled.LoadingMessage />
    </>
  );
}
