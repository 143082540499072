import { useIntl } from "react-intl";

import { RewardsStatus } from "@bees-grow-shared/business-components";

import * as Styled from "./ClubB.styles";
import EnrolledSince from "./enrolledSince/EnrolledSince";

export default function ClubB() {
  const { formatMessage } = useIntl();
  return (
    <Styled.Container>
      <EnrolledSince />
      <RewardsStatus>
        <Styled.SubTitle size="H5">
          {formatMessage({ id: "ClubB.POINTS_OFFER" })}
        </Styled.SubTitle>
      </RewardsStatus>
    </Styled.Container>
  );
}
