import { tokens } from "@bees-grow-shared/theme";
import { Heading, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Title = styled(Heading)`
  margin-bottom: 0.5rem;
`;

export const Message = styled.div`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].normal};
  line-height: ${tokens.measure["font-size"]["fontsize-5"]};
  margin-bottom: 1.5rem;
`;

export const LoadingTitle = styled(SkeletonLoader)`
  width: 5.375rem;
  height: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const LoadingMessage = styled(SkeletonLoader)`
  width: 12.5rem;
  height: 1.3rem;
  margin-bottom: 1.5rem;
`;
