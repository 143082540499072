import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  UpdateButton: {
    TEXT: "Atualizar",
  },
  GeneralError: {
    TITLE: "Algo deu errado.",
    DESCRIPTION:
      "Não é possível carregar as informações.\nTente recarregar a página.",
    ACTION: "Recarregar",
  },
  AccountOverview: {
    TITLE: "Visão geral do PDV",
  },
  ClubB: {
    TITLE: "Club B",
    ENROLLED_SINCE: "Inscrito desde {date}",
    POINTS_OFFER: "Ofertas baseadas em pontos",
  },
  PhoneNumbers: {
    TITLE: "Números de telefone",
    CALLED_TAG: "Chamado",
    COPIED_TAG: "Copiado",
    COPY_TOOLTIP: "Copiar",
    COPIED_TOOLTIP: "Copiado",
    NUMBER_RATE: {
      GOOD: "Bom número",
      BAD: "Número ruim",
      NEUTRAL: "Número neutro",
    },
    TOOLTIP: {
      INACTIVE: "Inativo",
      NO_ANSWER: "Sem resposta",
      SUCCESSFUL_CALL: "Chamada bem-sucedida",
      CLICK_CALL: "Clique para chamar",
      COPY_NUMBER: "Copiar número",
      EDIT_NUMBER: "Editar número",
    },
    PHONE_TYPE: {
      INPUTTED_PHONE: "Adicionado pelo agente",
      BEES_PHONE: "Telefone BEES",
      ERP_PHONE: "Telefone ERP",
    },
    BUTTON_ACTIVATE: "Ativar",
    BUTTON_ADD_PHONE: "Adicione um novo telefone",
    Modal: {
      DEACTIVATE_TITLE: "Desativar número de telefone?",
      ACTIVATE_TITLE: "Ativar número de telefone?",
      Button: {
        ACTIVATE: "Ativar",
        DEACTIVATE: "Desativar",
        CANCEL: "Cancelar",
      },
      ModalMessage: {
        ACTIVATE_NUMBER: "Você poderá fazer chamadas para o número",
        INACTIVATE_NUMBER: "Você não poderá fazer chamadas para o número",
        INACTIVATE_NOTE: "se estiver inativo.",
      },
    },
    AddEditPhoneModal: {
      ADD_TITLE: "Adicione novo telefone",
      EDIT_TITLE: "Editar telefone",
      FIELD_PHONE_NUMBER: "Número de telefone",
      FIELD_RESPONSIBLE: "Responsável",
      SUB_TITLE: "Preencha o formulário para adicionar um novo número.",
      PHONE_HINT_TEXT:
        "Inclui código do país, código de área e número de telefone.",
      NAME_HINT_TEXT: "Pessoa que atende este número.",
      REQUIRED_FIELD: "Este campo é obrigatório.",
      BUTTON_SAVE: "Salvar",
      BUTTON_CANCEL: "Cancelar",
      CREATE_SUCCESS_MESSAGE: "O número de telefone foi salvo com sucesso.",
      CREATE_ERROR_MESSAGE: "Não foi possível salvar o número de telefone.",
      EDIT_SUCCESS_MESSAGE: "O número de telefone foi editado.",
      EDIT_ERROR_MESSAGE: "Não foi possível editar o número de telefone.",
    },
    ToastMessage: {
      Success: {
        ACTIVATION: "O número de telefone ${} foi ativado.",
        INACTIVATION: "O número de telefone ${} foi desativado.",
        CLASSIFICATION: "O telefone foi classificado.",
      },
      Error: {
        ACTIVATION: "Não foi possível ativar o número de telefone ${}.",
        INACTIVATION: "Não foi possível desativar o número de telefone ${}.",
        CLASSIFICATION: "Não foi possível classificar o número de telefone.",
      },
    },
    Error: {
      MESSAGE:
        "Não foi possível carregar as informações dos números de telefone do PDV.",
      ACTION: "Tentar novamente",
    },
  },
  Tasks: {
    TITLE: "Tarefas de ligação",
    UNABLE_TO_LOAD: "Não foi possível carregar as informações das tarefas.",
    TRY_AGAIN: "Tentar novamente",
    EMPTY: "Não há tarefas atribuídas a esta POC.",
    GO_TO_TASKS: "Ir para tarefas",
    Global: {
      Title: {
        TOPLINE: "Tarefa de topline",
        MARKETPLACE: "Tarefa de marketplace",
      },
      Description: {
        TOPLINE: "Recomendar produtos com base na ordem sugerida.",
        MARKETPLACE: "Incentivar a compra de produtos do marketplace.",
      },
    },
    Local: {
      Description: {
        DEFAULT: "Tarefa personalizada criada pelas equipes de operações.",
      },
    },
  },
  PaymentMethod: {
    Methods: {
      CREDIT: "Crédito",
      CASH: "Dinheiro",
      BANK_SLIP: "Boleto",
      BANK_SLIP_INSTALLMENT: "Boleto Parcelado",
      CHECK: "Cheque",
      CREDIT_CARD_ONLINE: "Cartão de crédito on-line",
      GPA_PIX: "PIX na entrega",
      CREDIT_CARD_POS: "Cartão de crédito pos",
      PIX_AT_DELIVERY: "Pix na entrega",
      DIRECT_PAY_AT_DELIVERY: "Pagamento direto na entrega",
    },
  },
  LastOrders: {
    PREVIEW_TITLE: "Visualização dos últimos pedidos",
    SHOW_FULL_TABLE: "Mostrar tabela completa",
    Error: {
      MESSAGE: "Não foi possível carregar as informações dos últimos pedidos.",
      ACTION: "Tente novamente",
    },
    EmptyState: {
      MESSAGE:
        "Não há pedidos para mostrar. Assim que esta POC fizer um pedido, ele aparecerá aqui.",
    },
    Table: {
      CHANNEL: "Canal",
      ORDER_ID: "ID do pedido",
      STATUS: "Status",
      PAYMENT: "Pagamento",
      PLACED_ON: "Colocado em",
      PRODUCTS: "Produtos",
      DELIVERY: "Entrega",
      TOTAL: "Total",
    },
    Status: {
      PARTIAL_DELIVERY: "Entregue parcialmente",
      INVOICED: "Faturado",
      PENDING: "Pedido lançado",
      PENDING_PAYMENT: "Pagamento Pendente",
      PENDING_CANCELLATION: "Cancelamento em andamento",
      PLACED: "Pedido lançado",
      MODIFIED: "Aprovado com modificações",
      CANCELLED: "Pedido cancelado",
      CONFIRMED: "Pedido confirmado",
      AVAILABLE: "Aguardando retirada",
      DELIVERED: "Entregue",
      IN_TRANSIT: "Saiu pra entrega",
      DENIED: "Pedido Cancelado",
    },
  },
  Notes: {
    TITLE: "Notas",
    PLACEHOLDER: "Digite comentários sobre esta ligação...",
    ACTION: "Atualizar nota",
    Error: {
      MESSAGE: "Não é possível carregar as notas.",
      ACTION: "Tente novamente",
    },
  },
};

export default ptBR;
