import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const SubTitle = styled(Heading)`
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  margin-top: 1.5rem;
`;
